<script>
import _ from 'lodash'
import axiosApiClient from '../api/axiosApiClient'
import { rolePermissionCheck } from '@/permission/rolePermission'
import log from '@/lib/utils/log'
import { deleteLocation } from '@/api/locations-api'

export default {
	name: 'ActionButtons',
	props: {
		permissionModuleName: {
			type: String,
			required: true
		},
		commonButtons: {
			type: Array,
			default: () => [],
			example: ['new', 'import', 'export']
		},
		targetButtons: {
			type: Object,
			default: () => ({
				itemId: '',
				item: {},
				buttonList: []
			}),
			example: {
				itemId: '',
				item: {},
				buttonList: ['detail', 'edit', 'delete', 'copy']
			}
		},
		onDelete: {
			type: Function,
			default: null
		},
		onCopy: {
			type: Function,
			default: null
		}
	},
	data() {
		return {
			buttonConfig: {
				new: { label: 'Create New' },
				import: { label: 'Import' },
				export: { label: 'Export' },
				detail: { label: '🗒️' },
				edit: { label: '✏️' },
				delete: { label: '❌' },
				copy: { label: '♻️' }
			}
		}
	},
	created() {
		this.path = _.get(this.$route, 'path')
		this.itemId = _.get(this.targetButtons, 'itemId')
		this.item = _.get(this.targetButtons, 'item')
	},
	methods: {
		buttonPermission(button) {
			return rolePermissionCheck(this.permissionModuleName, button)
		},
		async onClickDelete(id) {
			if (
				!window.confirm(
					`Are you sure to delete entry(ies) with ID: '${id}'?`
				)
			) {
				return
			}
			if (!this.onDelete) {
				try {
					switch (this.path) {
						case '/locations':
							await deleteLocation(id)
							break
						default:
							await axiosApiClient({
								method: 'DELETE',
								url: `${this.path}/${id}`
							})
					}

					alert('Successfully submitted!')
					window.location.reload()
				} catch (error) {
					log.error(
						'Error on deleting entry(ies)',
						{ category: 'API' },
						error.stack
					)
					alert(error)
				}
			} else {
				await this.onDelete(id)
			}
		},
		onClickCopyForNew(item) {
			if (!item) {
				return
			}

			if (!this.onCopy) {
				return this.$router.push({
					path: `/${this.path}/new`
				})
			}
			this.onCopy(item)
		}
	}
}
</script>
<template>
	<span>
		<!-- common action buttons -->
		<template v-for="button in commonButtons">
			<router-link
				v-if="button === 'new' && buttonPermission(button)"
				:key="button"
				tag="button"
				class="action-buttons"
				:to="{ path: `${path}/${button}` }"
			>
				{{ buttonConfig[button].label }}
			</router-link>
		</template>

		<!-- target action buttons -->
		<template v-for="button in targetButtons.buttonList">
			<router-link
				v-if="button === 'detail' && buttonPermission(button)"
				:key="button"
				title="details"
				class="action-buttons"
				:to="{
					path: `${path}/${itemId}`
				}"
			>
				{{ buttonConfig[button].label }}
			</router-link>
			<router-link
				v-if="button === 'edit' && buttonPermission(button)"
				:key="button"
				title="edit"
				class="action-buttons"
				:to="{
					path: `${path}/edit/${itemId}`
				}"
			>
				{{ buttonConfig[button].label }}
			</router-link>
			<span
				v-if="button === 'delete' && buttonPermission(button)"
				:key="button"
				title="delete"
				class="action-buttons"
				@click="onClickDelete(itemId)"
			>
				{{ buttonConfig[button].label }}
			</span>
			<span
				v-if="button === 'copy' && buttonPermission(button)"
				:key="button"
				title="copy"
				class="action-buttons"
				@click="onClickCopyForNew(item)"
			>
				{{ buttonConfig[button].label }}
			</span>
		</template>
	</span>
</template>

<style scoped>
.action-buttons {
	padding-right: 10px !important;
}

button.action-btn {
	padding-left: 0;
	border: none;
	background: none;
}
</style>
