<script>
export default {
	name: 'FormInput',
	props: {
		id: {
			type: String,
			default: undefined
		},
		value: {
			type: [String, Number],
			default: null
		},
		label: {
			type: String,
			required: true
		},
		type: {
			type: String,
			default: null
		},
		inputStyle: {
			type: String,
			default: null
		},
		styles: {
			type: String,
			default: ''
		},
		disable: {
			type: Boolean,
			default: false
		},
		rules: {
			type: Array,
			default: () => []
		}
	}
}
</script>

<template>
	<q-input
		id="id || label"
		class="form-input"
		no-error-icon
		dense
		:label="label"
		:type="type"
		:input-style="inputStyle"
		:value="value"
		:disable="disable"
		:style="styles"
		:rules="rules"
		@change="$emit('input', $event.target.value)"
	/>
</template>

<style lang="scss" scoped>
.form-input.q-input {
	::v-deep .q-field__label {
		font-size: 11px;
	}
}
.form-input {
	width: 150px;
}
</style>
