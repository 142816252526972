<template>
	<div id="details-page-gen">
		<side-nav />
		<div id="content">
			<h2>
				{{ name }}
			</h2>
			<hr />
			<div class="flex">
				<div id="divTable">
					<div id="divTableBody">
						<div v-for="m in mapping" id="divTableRow" :key="m.key">
							<tree :label="m.header" :nodes="data[m.key]" />
						</div>
					</div>
				</div>
				<div
					v-if="permissions.delete && canDelete"
					style="padding-top: 3px; height: 40px"
				>
					<button id="delete" @click="onDelete">Delete</button>
				</div>
			</div>
			<div v-if="permissions.edit && canEdit" style="padding: 3px 10px">
				<router-link
					:to="{ path: `/${editEndpoint}`, query: { mode: 'edit' } }"
					tag="button"
				>
					Edit
				</router-link>
				<router-link
					v-if="canUndo"
					:to="{ path: `/${editEndpoint}`, query: { mode: 'undo' } }"
					tag="button"
				>
					Undo
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import S from 'string'
import axiosApiClient from '../api/axiosApiClient'
import { rolePermissionCheck } from '../permission/rolePermission'
import SideNav from './SideNav.vue'
import Tree from './Tree.vue'
import { deleteLocation, getLocation } from '@/api/locations-api'

export default {
	components: { SideNav, Tree },
	props: ['options'],
	data() {
		const recordId =
			this.options.recordId === undefined
				? this.$route.params.id
				: this.options.recordId
		let path = this.$route.path
		Object.values(this.$route.params).forEach(v => {
			path = path.replace(v, '')
		})
		path = path.replace(/\//g, '')
		const ctx = {
			data: {},
			id: this.options.id || 'id',
			name: `${
				this.options.name || S(this.options.resource).humanize().s
			}: ${recordId}`,
			editEndpoint: `${path}/edit/${recordId}`,
			permissions: {},
			mapping:
				this.options.display === undefined
					? []
					: this.options.display.map(k => ({
							header:
								k === 'dockId' ? 'Dock ID' : S(k).humanize().s,
							key: k
					  })),
			modifier:
				this.options.modifier || (async (ctx, data, store) => data),
			humanize: S,
			canEdit:
				this.options.canEdit === undefined
					? true
					: this.options.canEdit,
			canDelete:
				this.options.canDelete !== undefined
					? this.options.canDelete
					: true,
			recordId,
			recordQueryParam: this.options.recordQueryParam || {}
		}

		ctx.canUndo = localStorage.getItem(`/${ctx.editEndpoint}`)
			? true
			: false

		const initData = async () => {
			let response

			switch (this.options.resource) {
				case 'locations':
					response = {
						data: await getLocation(
							ctx.recordId,
							ctx.recordQueryParam
						)
					}
					break
				default:
					response = await axiosApiClient({
						// using old endpoints
						url: `${this.options.resource}/${ctx.recordId}`,
						params: ctx.recordQueryParam
					})
			}

			const isMappingDefined = ctx.mapping.length > 0
			const data = await ctx.modifier(ctx, response.data, this.$store)
			ctx.data = data
			if (!isMappingDefined) {
				const keys = Object.keys(ctx.data)
				const mapping = keys.map(k => ({
					header: S(k).humanize().s,
					key: k
				}))
				ctx.mapping.push(...mapping)
			}

			ctx.permissions = {
				edit: rolePermissionCheck(this.options.resource, 'edit'),
				delete: rolePermissionCheck(this.options.resource, 'delete')
			}
		}
		initData()
		return ctx
	},
	methods: {
		async onDelete() {
			try {
				if (
					!window.confirm(
						`Are you sure to delete the entry with ID '${this.$route.params.id}'?`
					)
				) {
					return
				}

				switch (this.options.resource) {
					case 'locations': // new endpoints
						await deleteLocation(this.$route.params.id)
						break
					default:
						// old endpoints
						await axiosApiClient({
							method: 'DELETE',
							url: `${this.options.resource}/${this.$route.params.id}`
						})
				}

				const redirectUrl = this.$route.path.split('/') // ["", base, "param"]
				this.$router.push(`/${redirectUrl[1]}`) // use baseUrl
			} catch (error) {
				alert(error)
			}
		}
	}
}
</script>

<style>
#details-page-gen {
	width: 98%;
	margin: auto;
	display: flex;
	position: absolute;
}
#content {
	width: 100%;
}
#divTable {
	display: table;
	width: 100%;
	text-align: left;
}
#divTableRow {
	display: table-row;
	width: 100%;
}
#divTableCell {
	float: left;
	display: table-cell;
}
.leftCell {
	width: 15%;
}
#divTableBody {
	display: table-row-group;
}
.key {
	font-weight: bold;
	width: 25%;
}
hr {
	width: 100%;
}
#delete {
	float: right;
}
.flex {
	display: flex;
}
</style>
