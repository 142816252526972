<script>
import _ from 'lodash'
import { rolePermissionCheck } from '@/permission/rolePermission'
import SideNav from '@/components/SideNav.vue'
import FormInput from '@/components/FormInput.vue'
import FormSchema from './components/FormSchema.vue'
import { mapState, mapActions } from 'vuex'

export default {
	name: 'CourierEdit',
	components: { SideNav, FormInput, FormSchema },
	data: () => {
		return {
			displayForm: false,
			canSubmit: rolePermissionCheck('users', 'edit'),
			canSyncCourier: rolePermissionCheck('users', 'edit')
		}
	},
	computed: {
		...mapState('couriers', ['courierInfo', 'formData'])
	},
	async created() {
		const id = this.$route.params.id
		await this.getCourierById(id)
		await this.getLocaleOptions()
	},
	methods: {
		...mapActions('couriers', [
			'getLocaleOptions',
			'getCourierById',
			'getCityByLocationCode',
			'getDrivers',
			'updateCourier',
			'syncCourier'
		]),
		async updateCourierFunc(courier) {
			if (_.isNil(courier.driverId)) {
				return {
					errors: [
						{
							detail: 'Missing Driver ID, Please get update from core system'
						}
					]
				}
			}

			const aliases = _.map(['phone', 'telegram'], aliasType => {
				if (_.isEmpty(courier[aliasType])) {
					return
				}
				return {
					type: _.toUpper(aliasType),
					id: courier[aliasType],
					preferredChannel: aliasType === 'phone'
				}
			})
			return await this.updateCourier({
				id: this.$route.params.id,
				info: {
					name: courier.name || '',
					locale: courier.locale || ''
				},
				courier: {
					driver: courier.driverId.toString(),
					location: courier.locationCode
				},
				clients: _.map(
					_.filter(
						courier.clients,
						client => !_.isEmpty(client.clientId)
					),
					'clientId'
				),
				aliases: _.compact(aliases),
				isVerified: courier.isVerified
			})
		},
		async dataSync() {
			this.syncCourier()
			this.canSyncCourier = false
			alert('Courier info updated!')
		}
	}
}
</script>
<template>
	<div id="new-page">
		<side-nav />
		<div id="new-page-content">
			<h2>Edit Courier</h2>
			<div>
				<div>
					<h6>LLM+ User ID</h6>
					<form-input
						v-model="courierInfo.id"
						label="User Id"
						disable
					/>
				</div>

				<div>
					<h6>Lalamove Driver ID</h6>
					<form-input
						v-model="courierInfo.driverId"
						label="Driver Id"
						disable
					/>
					<q-btn
						id="sync-courier-btn"
						label="GET UPDATE FROM LLM CORE SYSTEM"
						no-caps
						color="orange-9"
						:disable="!canSyncCourier"
						@click="dataSync"
					/>
				</div>
			</div>
			<form-schema
				submit-label="Update"
				:courier-info="courierInfo"
				:submit-func="updateCourierFunc"
				:can-submit="canSubmit"
				:locale-options="formData.localeOptions"
			/>
		</div>
	</div>
</template>
<style lang="scss" scoped>
#new-page {
	width: 100%;
	margin: auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: absolute;
	#new-page-content {
		width: 80%;
		position: sticky;
		> div {
			> div {
				display: -webkit-box;
				padding: 20px 0 0 0;
				height: auto;
				h6 {
					width: 200px;
				}
				label {
					width: 300px;
				}
			}
			::v-deep button {
				height: 40px;
				margin-left: 10px;
			}
		}
	}
}
</style>
