<template>
	<div class="render-value" style="white-space: pre-wrap">
		<template
			v-if="
				typeof value === 'string' &&
				value.startsWith('http') &&
				['.jpg', '.jpeg', '.png', '.svg', '.gif'].some(i =>
					value.toLowerCase().endsWith(i)
				)
			"
		>
			<span id="divTableCell">
				<img
					:src="value"
					:alt="'IMAGE NOT FOUND ' + value"
					style="max-width: 100px; max-height: 75px"
					@click="openNewTab(value)"
				/>
			</span>
		</template>
		<template
			v-else-if="
				typeof value === 'string' &&
				value.match(
					/^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01]).*$/
				)
			"
		>
			<span id="divTableCell">
				{{ momentTZ.tz(value, timezone).format('YYYY-MM-DD HH:mm') }}
			</span>
		</template>

		<!-- template for raw date, use 'rawdate-' prefix to prevent match regexp for date, the prefix will not display -->
		<template
			v-else-if="
				typeof value === 'string' &&
				value.match(
					/^rawdate-(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01]).*$/
				)
			"
		>
			<span id="divTableCell">{{ value.replace('rawdate-', '') }}</span>
		</template>

		<template
			v-else-if="
				typeof value === 'string' && /^#[0-9A-F]{6}$/i.test(value)
			"
		>
			<span
				id="divTableCell"
				:style="{
					['background-color']: value,
					width: '25px',
					height: '25px',
					['margin-left']: '10px'
				}"
			/>
		</template>
		<template
			v-else-if="
				typeof value === 'object' && value !== null && value._isVue
			"
		>
			<span id="divTableCell"><component :is="value.$options" /></span>
		</template>
		<template v-else>
			<span id="divTableCell">{{ value }}</span>
		</template>
	</div>
</template>
<script>
import moment from 'moment-timezone'

export default {
	name: 'RenderValue',
	props: ['value'],
	data() {
		const data = {
			momentTZ: moment,
			timezone: moment.tz.guess()
		}
		return data
	},
	methods: {
		openNewTab(url) {
			window.open(url, '_blank')
		}
	}
}
</script>

<style scoped>
img {
	cursor: pointer;
}
span {
	max-width: 500px;
	overflow-wrap: break-word;
}
</style>
