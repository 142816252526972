<script>
import _ from 'lodash'
import { mapActions, mapState } from 'vuex'
import { rolePermissionCheck } from '@/permission/rolePermission'
import CommonForm from '@/components/CommonForm.vue'
import FormInput from '@/components/FormInput.vue'
import FormSelect from '@/components/FormSelect.vue'
import TooltipButton from '@/components/TooltipButton.vue'
import { getLocations } from '@/api/locations-api'
import { getClients } from '@/api/clients-api'

export default {
	name: 'FormFilter',
	components: { CommonForm, FormInput, FormSelect, TooltipButton },
	props: {
		permissionModuleName: {
			type: String,
			required: true
		},
		commonButtons: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			id: null,
			driverId: null,
			name: null,
			phone: null,
			locationId: null,
			clientId: null,
			optionsData: {
				locationOptions: [],
				clientOptions: []
			}
		}
	},
	computed: {
		...mapState('couriers', ['filter'])
	},
	async created() {
		this.canCreate = rolePermissionCheck(this.permissionModuleName, 'new')
		this.optionsData.locationOptions = _.map(
			_.get(await getLocations(), 'data', []),
			'id'
		)
		this.optionsData.clientOptions = _.map(await getClients(), 'id')

		await this.syncLocalWithStore()
	},
	beforeDestroy() {
		this.resetFilter()
		this.syncLocalWithStore()
	},
	methods: {
		...mapActions('couriers', ['getCouriers', 'resetFilter']),
		submit() {
			return this.getCouriers({
				..._.omit(this.$data, 'optionsData')
			})
		},
		async reset() {
			this.resetFilter()
			this.syncLocalWithStore()
			await this.submit()
		},
		syncLocalWithStore() {
			this.id = this.filter.id
			this.driverId = this.filter.driverId
			this.name = this.filter.name
			this.phone = this.filter.phone
			this.locationId = this.filter.locationId
			this.clientId = this.filter.clientId
		},
		courierCreation() {
			this.$router.push({ path: '/couriers/new' })
		}
	}
}
</script>

<template>
	<common-form :reset="reset" :submit="submit">
		<form-input
			v-model="id"
			label="Courier Id"
			:rules="$validator.nullableID('Courier Id')"
		/>
		<form-input
			v-model="driverId"
			label="LLM Driver Id"
			:rules="$validator.nullableID('LLM Driver Id')"
		/>
		<form-input v-model="name" label="Name" />
		<form-input v-model="phone" label="Phone" />
		<form-select
			v-model="locationId"
			label="Location"
			nullable
			:options="optionsData.locationOptions"
		/>
		<form-select
			v-model="clientId"
			label="Client"
			nullable
			:options="optionsData.clientOptions"
		/>

		<template #extra-buttons>
			<div class="action-btn">
				<span>Action:</span>
				<tooltip-button
					v-if="commonButtons.includes('new')"
					:tooltips="['Permission: Create Courier']"
					:disable="!canCreate"
					color="primary"
					label="Create Courier"
					@click="courierCreation"
				/>
			</div>
		</template>
	</common-form>
</template>

<style lang="scss" scoped>
.action-btn {
	display: inline-table;
	span {
		display: table-cell;
		padding: 0 10px 0 20px;
	}
}
</style>
