<script>
import _ from 'lodash'
import FormInput from './FormInput.vue'
import FormSelect from './FormSelect.vue'
import DateInput from './DateInput.vue'
import FormAddRemoveBtn from './FormAddRemoveBtn.vue'

export default {
	name: 'FormArray',
	components: { FormInput, FormSelect, DateInput, FormAddRemoveBtn },
	props: {
		dataList: {
			type: Array,
			default: () => null,
			validator: function (list) {
				return _.isEmpty(list.find(l => _.isEmpty(l)))
			}
		},
		dataSchema: {
			type: Object,
			default: () => null,
			validator: function (schema) {
				return _.isEmpty(
					Object.keys(schema).filter(
						key =>
							!['type', 'label', 'rules'].every(
								prop => prop in schema[key]
							) ||
							(schema[key].type === 'select' &&
								!_.has(schema[key], 'options')) ||
							(schema[key].type === 'datetime' &&
								!_.has(schema[key], 'maskFormat'))
					)
				)
			}
		}
	},
	data() {
		return {
			dataContent: this.dataList
		}
	},
	watch: {
		dataList: function (newVal) {
			this.dataContent = []
			newVal.forEach(element => {
				this.dataContent.push(element)
			})
		}
	},
	methods: {
		onClickAdd() {
			const tempObj = _.transform(
				this.dataSchema,
				(result, value, key) => (result[key] = ''),
				{}
			)

			this.$emit('onAdd', tempObj)
		},
		onClickRemove() {
			this.$emit('onRemove')
		},
		generateLabel(item) {
			const { label, required } = item
			return `${label}${required ? '*' : ''}`
		}
	}
}
</script>

<template>
	<q-list separator bordered class="form-array">
		<template v-for="(itemList, itemListIndex) in dataContent">
			<template v-for="(item, itemKey) in dataContent[itemListIndex]">
				<!-- FormInput -->
				<form-input
					v-if="dataSchema[itemKey].type === 'input'"
					:key="itemListIndex + '-' + itemKey"
					v-model="dataContent[itemListIndex][itemKey]"
					:label="generateLabel(dataSchema[itemKey])"
					:rules="dataSchema[itemKey].rules"
					styles="width:100%;padding-top:5px;"
				/>
				<!-- FormSelect -->
				<form-select
					v-if="dataSchema[itemKey].type === 'select'"
					:key="itemListIndex + '-' + itemKey"
					v-model="dataContent[itemListIndex][itemKey]"
					:label="generateLabel(dataSchema[itemKey])"
					:rules="dataSchema[itemKey].rules"
					:options="dataSchema[itemKey].options"
					styles="width:100%;padding-top:5px;"
				/>
				<!-- DateInput -->
				<date-input
					v-if="dataSchema[itemKey].type === 'datetime'"
					:key="itemListIndex + '-' + itemKey"
					v-model="dataContent[itemListIndex][itemKey]"
					:label="generateLabel(dataSchema[itemKey])"
					:rules="dataSchema[itemKey].rules"
					:mask-format="dataSchema[itemKey].maskFormat"
					styles="width:100%;padding-top:5px;"
				/>
			</template>
		</template>
		<!-- + - buttons -->
		<form-add-remove-btn :on-add="onClickAdd" :on-remove="onClickRemove" />
	</q-list>
</template>

<style lang="scss" scoped>
.form-array {
	width: 420px;
	padding: 10px;

	::v-deep label {
		margin-top: 10px;
	}
}
</style>
