var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout',{attrs:{"class-name":"couriers-page","title":"Couriers"}},[_c('form-filter',{attrs:{"permission-module-name":"users","common-buttons":_vm.actionButtons.commonButtons}}),_c('common-table',{attrs:{"row-key":"id","data":_vm.data,"columns":_vm.columns,"on-page-change":_vm.onPageChange,"total-page":_vm.totalPage,"current-page":_vm.filter.page,"current-sort":{
			sortBy: _vm.filter.sortBy,
			descending: _vm.filter.sortDirection === 'DESC'
		},"binary-state-sort":""},scopedSlots:_vm._u([{key:"btn",fn:function({ row }){return [_c('action-buttons',{attrs:{"permission-module-name":"users","target-buttons":{
					itemId: row.id,
					item: row,
					buttonList: _vm.actionButtons.targetButtons
				},"on-delete":_vm.onDelete}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }