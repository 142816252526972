<script>
import _ from 'lodash'
import CommonTable from '@/components/CommonTable.vue'
import CommonForm from '@/components/CommonForm.vue'
import FormInput from '@/components/FormInput.vue'
import FormSelect from '@/components/FormSelect.vue'
export default {
	name: 'DriverSearch',
	components: { CommonTable, CommonForm, FormInput, FormSelect },
	props: {
		createCourier: {
			type: Function,
			default: row => {}
		},
		searchDriver: {
			type: Function,
			default: () => {}
		},
		resetDriver: {
			type: Function,
			default: () => {}
		},
		disable: {
			type: Boolean,
			required: true
		},
		areaOptions: {
			type: Array,
			required: true
		},
		driverList: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			areaCode: null,
			phoneNum: '',
			columns: [
				{
					name: 'cityCode',
					label: 'City (Location Code)',
					field: 'cityCode',
					align: 'left'
				},
				{
					name: 'driverId',
					label: 'LLM Driver ID',
					field: 'driverId',
					align: 'left'
				},
				{
					name: 'driverName',
					label: 'Name',
					field: 'driverName',
					align: 'left'
				},
				{
					name: 'driverPhone',
					label: 'Phone Number',
					field: 'driverPhone',
					align: 'left'
				},
				{
					name: 'driverVehiclePlate',
					label: 'Vehicle Plate',
					field: 'driverVehiclePlate',
					align: 'left'
				},
				{
					name: 'driverStatus',
					label: 'Status',
					field: 'driverStatus',
					format: status =>
						status === 1 ? 'Normal' : 'Temporarily Banned',
					align: 'left'
				},
				{
					name: 'actions',
					label: 'Actions',
					align: 'left',
					style: 'width: 200px;'
				}
			]
		}
	},
	methods: {
		async onSubmit() {
			const countryId = _.get(
				_.find(this.areaOptions, { value: this.areaCode }),
				'countryId'
			)
			const phoneNumber = `${this.areaCode}${this.phoneNum}`
			await this.searchDriver({
				phoneNumber,
				countryId
			})
		},
		async onReset() {
			this.areaCode = null
			this.phoneNum = ''
			await this.resetDriver()
		}
	}
}
</script>
<template>
	<div>
		<common-form
			id="driver-search"
			:submit="onSubmit"
			submit-label="Search"
			:reset="onReset"
			:disable-btn="disable"
		>
			<div>
				<h5>Lalamove Driver Phone Number</h5>
			</div>
			<form-select
				v-model="areaCode"
				label="Area code"
				:options="areaOptions"
				:rules="$validator.required('Area code')"
				:disable="disable"
			/>
			<form-input
				v-model="phoneNum"
				label="Driver phone number"
				:rules="$validator.numericString('Driver phone number')"
				:disable="disable"
			/>
		</common-form>
		<common-table
			id="driver-result"
			:data="driverList"
			:columns="columns"
			:on-page-change="() => {}"
			:total-page="1"
		>
			<template #actions="{ row }">
				<q-btn
					v-if="row.driverStatus === 1"
					flat
					label="Create LLM+ Courier Account ->"
					:disable="disable"
					@click="createCourier(row)"
				/>
				<span v-else>-</span>
			</template>
		</common-table>
	</div>
</template>
<style lang="scss" scoped>
#driver-search {
	::v-deep label.form-select {
		width: 250px;
	}
	::v-deep label.form-input {
		width: 200px;
	}
}
#driver-result {
	::v-deep thead {
		background-color: #bdbdbd;
		border-style: hidden;
	}
	::v-deep tbody {
		background-color: #eee;
	}
	::v-deep td {
		padding-right: 16px;
		padding-top: 0;
		padding-bottom: 0;
	}
	::v-deep .q-btn__wrapper {
		padding: 0;
	}
	::v-deep .q-table__top,
	::v-deep .q-table__bottom {
		display: none;
	}
	::v-deep button {
		color: #1976d2;
	}
}
</style>
