<script>
import _ from 'lodash'
import CommonForm from '@/components/CommonForm.vue'
import FormInput from '@/components/FormInput.vue'
import FormSelect from '@/components/FormSelect.vue'
import FormArray from '@/components/FormArray.vue'
import FormCheckbox from '@/components/FormCheckbox.vue'

export default {
	name: 'FormSchema',
	components: {
		CommonForm,
		FormInput,
		FormSelect,
		FormArray,
		FormCheckbox
	},
	props: {
		courierInfo: {
			type: Object,
			required: true
		},
		submitFunc: {
			type: Function,
			required: true
		},
		canSubmit: {
			type: Boolean,
			required: true
		},
		localeOptions: {
			type: Array,
			default: () => []
		},
		submitLabel: {
			type: String,
			default: 'Create'
		}
	},
	data() {
		const schema = {
			clients: {
				clientId: {
					type: 'input',
					label: 'Client Id',
					rules: this.$validator.clientId('client Id')
				}
			}
		}
		return {
			driverId: null,
			name: '',
			locationCode: '',
			locale: '',
			phone: '',
			telegram: '',
			clients: [],
			isVerified: false,
			schema
		}
	},
	watch: {
		courierInfo: function (newVal) {
			this.driverId = newVal.driverId
			this.name = newVal.name
			this.locationCode = newVal.locationCode
			this.locale = newVal.locale
			this.phone = newVal.phone
			this.telegram = newVal.telegram
			this.clients = _.map(newVal.clients)
			this.isVerified = newVal.isVerified
		}
	},
	methods: {
		onClickAdd(index, newObj = {}) {
			if (_.has(this.$data, index)) {
				this.$data[index].push(newObj)
			}
		},
		onClickRemove(index) {
			if (!_.isEmpty(this.$data[index])) {
				this.$data[index].pop()
			}
		},
		async onSubmit() {
			// Build the submit data
			const resp = await this.submitFunc(this.$data)
			if (resp && !resp.errors) {
				alert('Successfully submitted!')
				return this.$router.push({
					path: `/couriers`
				})
			} else {
				const error = _.get(resp, 'errors', '')[0]
				alert(
					`Submitted failed!\ndetail: ${_.get(error, 'detail', '')}`
				)
			}
		},
		async onReset() {
			this.driverId = this.courierInfo.driverId
			this.name = this.courierInfo.name
			this.locationCode = this.courierInfo.locationCode
			this.locale = this.courierInfo.locale
			this.phone = this.courierInfo.phone
			this.telegram = this.courierInfo.telegram
			this.clients = _.map(this.courierInfo.clients)
			this.isVerified = this.courierInfo.isVerified
		}
	}
}
</script>

<template>
	<common-form
		layout="vertical"
		:submit-label="submitLabel"
		:submit="onSubmit"
		:reset="onReset"
	>
		<!-- Name -->
		<div class="row-fields">
			<h6>Name</h6>
			<form-input v-model="name" label="Name" disable="disable" />
		</div>

		<!-- City (Location Code) -->
		<div class="row-fields">
			<h6>City (Location Code)</h6>
			<form-input
				v-model="locationCode"
				label="City (Location Code)"
				disable="disable"
			/>
		</div>

		<!-- Locale -->
		<div class="row-fields">
			<h6 class="required">Locale</h6>
			<form-select
				v-model="locale"
				label="Locale"
				:options="localeOptions"
				:rules="$validator.required('Locale')"
			/>
		</div>

		<!-- Phone (Alias)-->
		<div class="row-fields">
			<h6>Phone (Alias)</h6>
			<form-input
				v-model="phone"
				label="Phone (Alias)"
				:rules="$validator.required('Phone')"
				disable="disable"
			/>
		</div>

		<!-- Telegram (Alias) -->
		<div class="row-fields">
			<h6>Telegram (Alias)</h6>
			<form-input v-model="telegram" label="Telegram (Alias)" />
		</div>

		<div class="row-fields">
			<h6>Is Verified</h6>
			<form-checkbox v-model="isVerified" />
		</div>

		<!-- Clients -->
		<div style="padding-top: 20px">
			<q-expansion-item
				id="clients"
				label="Clients"
				expand-separator
				style="width: 500px"
			>
				<form-array
					:data-list="clients"
					:data-schema="schema.clients"
					@onAdd="tempObj => onClickAdd('clients', tempObj)"
					@onRemove="() => onClickRemove('clients')"
				/>
			</q-expansion-item>
		</div>
	</common-form>
</template>

<style lang="scss" scoped>
.row-fields {
	h6.required::after {
		content: '*';
		color: red;
	}
	display: -webkit-box;
	padding: 20px 0 0 0;
	height: auto;
	h6 {
		width: 200px;
	}
	label {
		width: 300px;
	}
}
</style>
