<script>
import _ from 'lodash'
import S from 'string'
import SideNav from '@/components/SideNav.vue'
import { rolePermissionCheck } from '@/permission/rolePermission'
import DriverSearch from './components/DriverSearch.vue'
import FormSchema from './components/FormSchema.vue'

import { mapState, mapActions } from 'vuex'

export default {
	name: 'CourierNew',
	components: { SideNav, DriverSearch, FormSchema },
	data: () => {
		return {
			name: 'create couriers',
			courier: {},
			displayForm: false,
			canSubmit: rolePermissionCheck('users', 'new'),
			areaOptions: [],
			localeOptions: []
		}
	},
	computed: {
		...mapState('couriers', ['formData'])
	},
	async created() {
		this.title = `${S(this.name).humanize().s}`
		await this.getCountryAreaCodeOptions()
		this.areaOptions = this.formData.countryAreaCodeOptions
		await this.getLocaleOptions()
		this.localeOptions = this.formData.localeOptions
	},
	destroyed() {
		this.resetState()
	},
	methods: {
		...mapActions('couriers', [
			'getCountryAreaCodeOptions',
			'getLocaleOptions',
			'getDrivers',
			'createCouriers',
			'resetDrivers',
			'resetState'
		]),
		async onClickCreateCourier(courier) {
			this.courier = {
				name: _.get(courier, 'driverName', ''),
				locationCode: _.get(courier, 'cityCode', ''),
				locale: '',
				phone: _.get(courier, 'driverPhone', ''),
				telegram: '',
				clients: [],
				driverId: _.get(courier, 'driverId'),
				isVerified: true
			}
			this.displayForm = true
		},
		async createCourierFunc(courier) {
			const aliases = _.map(['phone', 'telegram'], aliasType => {
				if (_.isEmpty(courier[aliasType])) {
					return
				}
				return {
					type: _.toUpper(aliasType),
					id: courier[aliasType],
					preferredChannel: aliasType === 'phone'
				}
			})
			return await this.createCouriers([
				{
					info: {
						name: courier.name || '',
						locale: courier.locale || ''
					},
					courier: {
						driver: _.get(courier, 'driverId', '').toString(),
						location: courier.locationCode
					},
					clients: _.map(
						_.filter(
							courier.clients,
							client => !_.isEmpty(client.clientId)
						),
						'clientId'
					),
					aliases: _.compact(aliases),
					isVerified: courier.isVerified
				}
			])
		}
	}
}
</script>
<template>
	<div id="new-page">
		<side-nav />
		<div id="new-page-content">
			<h2>
				{{ title }}
			</h2>
			<driver-search
				:area-options="areaOptions"
				:search-driver="getDrivers"
				:reset-driver="resetDrivers"
				:disable="displayForm"
				:create-courier="onClickCreateCourier"
				:driver-list="formData.driverList"
			/>
			<form-schema
				v-show="displayForm"
				:courier-info="courier"
				:submit-func="createCourierFunc"
				:can-submit="canSubmit"
				:locale-options="localeOptions"
			/>
		</div>
	</div>
</template>
<style lang="scss" scoped>
#new-page {
	width: 100%;
	margin: auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: absolute;

	#new-page-content {
		width: 80%;
		position: sticky;
	}
}
</style>
