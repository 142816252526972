<template>
	<div class="tree">
		<div v-if="Array.isArray(nodes) && nodes.length > 0">
			<details>
				<summary v-if="label !== undefined">
					<span class="key collapsible">{{ label }}</span>
				</summary>
				<tree v-for="(node, key) in nodes" :key="key" :nodes="node" />
			</details>
		</div>
		<div v-else-if="typeof nodes === 'object'">
			<div
				v-if="
					typeof nodes === 'object' && nodes !== null && nodes._isVue
				"
			>
				<div v-if="label !== undefined" :id="cell" class="key">
					{{ label }}
				</div>
				<div>
					<render-value :value="nodes" :class="cell" />
				</div>
			</div>
			<div v-else-if="label !== undefined">
				<details>
					<summary>
						<span class="key collapsible">{{ label }}</span>
					</summary>
					<div id="divTable">
						<div id="divTableBody">
							<div
								v-for="(value, key) in nodes"
								id="divTableRow"
								:key="key"
							>
								<div
									v-if="Array.isArray(value)"
									style="padding: 3px 10px"
								>
									<details>
										<summary>
											<span class="key collapsible">
												{{ key }}
											</span>
										</summary>
										<tree
											v-for="(node, key) in value"
											:key="key"
											:nodes="node"
										/>
									</details>
								</div>
								<div
									v-else-if="typeof value === 'object'"
									style="padding: 3px 10px"
								>
									<details>
										<summary>
											<span class="key collapsible">
												{{ key }}
											</span>
										</summary>
										<tree
											v-for="(node, key) in value"
											:key="key"
											:nodes="node"
											:label="key"
										/>
									</details>
								</div>
								<div v-else>
									<span id="divTableCell" class="key">
										{{ key }}
									</span>
									<render-value :value="value" />
								</div>
							</div>
						</div>
					</div>
				</details>
			</div>
			<div v-else>
				<div id="divTable">
					<div id="divTableBody">
						<div
							v-for="(value, key) in nodes"
							id="divTableRow"
							:key="key"
						>
							<div
								v-if="Array.isArray(value)"
								style="padding: 3px 10px"
							>
								<details>
									<summary>
										<span class="key collapsible">
											{{ key }}
										</span>
									</summary>
									<tree
										v-for="(node, key) in value"
										:key="key"
										:nodes="node"
									/>
								</details>
							</div>
							<div
								v-else-if="typeof value === 'object'"
								style="padding: 3px 10px"
							>
								<details>
									<summary>
										<span class="key collapsible">
											{{ key }}
										</span>
									</summary>
									<tree
										v-for="(node, key) in value"
										:key="key"
										:nodes="node"
										:label="key"
									/>
								</details>
							</div>
							<div v-else>
								<div id="divTableCell" class="key">
									{{ key }}
								</div>
								<div>
									<render-value :value="value" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<div id="divTable">
				<div id="divTableBody">
					<div id="divTableRow">
						<div v-if="label !== undefined" :id="cell" class="key">
							{{ label }}
						</div>
						<div>
							<render-value :value="nodes" :class="cell" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import moment from 'moment-timezone'
import RenderValue from './RenderValue.vue'

export default {
	name: 'Tree',
	components: { RenderValue },
	props: ['label', 'nodes'],
	data() {
		const data = {
			momentTZ: moment,
			timezone: moment.tz.guess()
		}
		return data
	},
	computed: {
		cell() {
			let cell = 'key'
			if (this.label !== undefined) {
				cell = 'divTableCell'
			}
			return cell
		}
	}
}
</script>

<style>
.tree {
	padding: 3px 10px;
}
.collapsible {
	cursor: pointer;
}
details > summary::-webkit-details-marker {
	display: none;
}
details > summary::before {
	content: '\25B8  ';
}
details[open] > summary::before {
	content: '\25BE  ';
}
</style>
