<script>
export default {
	name: 'TooltipButton',
	props: {
		tooltips: {
			type: Array,
			required: true
		}
	}
}
</script>

<template>
	<div class="tooltip-button">
		<q-btn
			v-bind="{ ...$props, ...$attrs }"
			no-caps
			@click="$emit('click')"
		/>
		<q-tooltip v-if="$attrs.disable">
			<div v-for="tooltip in tooltips" :key="tooltip">{{ tooltip }}</div>
		</q-tooltip>
	</div>
</template>
