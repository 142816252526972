<script>
export default {
	name: 'FormInput',
	props: {
		value: {
			type: Boolean,
			default: false
		},
		label: {
			type: String,
			default: ''
		}
	},
	methods: {
		onInput(value) {
			this.$emit('input', value)
		}
	}
}
</script>

<template>
	<q-checkbox
		class="form-checkbox"
		:label="label"
		:value="value"
		@input="onInput"
	/>
</template>

<style lang="scss" scoped></style>
