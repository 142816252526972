<script>
import { rolePermissionCheck } from '@/permission/rolePermission'
import DetailsPageGen from '@/components/DetailsPageGen.vue'

export default {
	name: 'CourierDetails',
	components: { DetailsPageGen },
	data: () => {
		return {
			options: {
				resource: 'users',
				canEdit: rolePermissionCheck('users', 'edit'),
				canDelete: rolePermissionCheck('users', 'delete')
			}
		}
	}
}
</script>
<template>
	<details-page-gen :options="options" />
</template>
