<script>
/* global moment _*/
import { format } from '@/lib/plugins/date'
import { deleteUser } from '@/api/users-api'
import FormFilter from './components/FormFilter.vue'
import Layout from '@/components/Layout.vue'
import CommonTable from '@/components/CommonTable.vue'
import ActionButtons from '@/components/ActionButtons.vue'
import { mapState, mapActions } from 'vuex'

export default {
	name: 'Couriers',
	components: {
		CommonTable,
		Layout,
		ActionButtons,
		FormFilter
	},
	data() {
		return {
			actionButtons: {
				commonButtons: ['new'],
				targetButtons: ['detail', 'edit', 'delete']
			},
			columns: [
				{
					name: 'id',
					label: 'Id',
					field: 'id',
					align: 'left',
					sortable: true
				},
				{
					name: 'driverId',
					label: 'LLM Driver ID',
					field: 'driverId',
					align: 'left',
					sortable: true
				},
				{
					name: 'locationId',
					label: 'Location',
					field: 'locationId',
					align: 'left'
				},
				{
					name: 'name',
					label: 'Name',
					field: 'name',
					align: 'left'
				},
				{
					name: 'phone',
					label: 'Phone',
					field: 'phone',
					align: 'left'
				},
				{
					name: 'telegram',
					label: 'Telegram',
					field: 'telegram',
					format: telegram => (_.isEmpty(telegram) ? '❌' : '✅'),
					align: 'left'
				},
				{
					name: 'clients',
					label: 'Clients',
					field: 'clients',
					align: 'left'
				},
				{
					name: 'createdAt',
					label: `Created At (${format(moment(), null, 'UTCZ')})`,
					field: 'createdAt',
					format: this.$dateUtils.unaryFormat,
					align: 'left',
					sortable: true
				},
				{
					name: 'btn',
					label: '-',
					align: 'left'
				}
			]
		}
	},
	computed: {
		...mapState('couriers', ['totalPage', 'data', 'filter'])
	},
	methods: {
		...mapActions('couriers', ['getCouriers', 'resetFilter']),
		async onPageChange(pagination) {
			const { sortBy, descending, page, limit } = pagination

			await this.getCouriers({
				sortBy: sortBy || 'id',
				sortDirection: sortBy && !descending ? 'ASC' : 'DESC',
				page,
				limit
			})
		},
		async onDelete(id) {
			try {
				await deleteUser(id)
				alert('Successfully submitted!')
				window.location.reload()
			} catch (error) {
				alert(error)
			}
		}
	}
}
</script>

<template>
	<layout class-name="couriers-page" title="Couriers">
		<form-filter
			permission-module-name="users"
			:common-buttons="actionButtons.commonButtons"
		/>
		<common-table
			row-key="id"
			:data="data"
			:columns="columns"
			:on-page-change="onPageChange"
			:total-page="totalPage"
			:current-page="filter.page"
			:current-sort="{
				sortBy: filter.sortBy,
				descending: filter.sortDirection === 'DESC'
			}"
			binary-state-sort
		>
			<template #btn="{ row }">
				<action-buttons
					permission-module-name="users"
					:target-buttons="{
						itemId: row.id,
						item: row,
						buttonList: actionButtons.targetButtons
					}"
					:on-delete="onDelete"
				/>
			</template>
		</common-table>
	</layout>
</template>
