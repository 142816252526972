<script>
export default {
	name: 'FormAddRemoveBtn',
	props: {
		onAdd: {
			type: Function,
			required: true
		},
		onRemove: {
			type: Function,
			required: true
		}
	}
}
</script>

<template>
	<div class="form-add-remove-btn">
		<q-btn label="+" no-caps color="orange-9" @click="onAdd" />
		<q-btn label="-" outline no-caps @click="onRemove" />
	</div>
</template>

<style lang="scss" scoped>
.form-add-remove-btn {
	margin-top: 16px;
	> button {
		margin: 10px 10px 10px 0;
	}
}
</style>
