var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"render-value",staticStyle:{"white-space":"pre-wrap"}},[(
			typeof _vm.value === 'string' &&
			_vm.value.startsWith('http') &&
			['.jpg', '.jpeg', '.png', '.svg', '.gif'].some(i =>
				_vm.value.toLowerCase().endsWith(i)
			)
		)?[_c('span',{attrs:{"id":"divTableCell"}},[_c('img',{staticStyle:{"max-width":"100px","max-height":"75px"},attrs:{"src":_vm.value,"alt":'IMAGE NOT FOUND ' + _vm.value},on:{"click":function($event){return _vm.openNewTab(_vm.value)}}})])]:(
			typeof _vm.value === 'string' &&
			_vm.value.match(
				/^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01]).*$/
			)
		)?[_c('span',{attrs:{"id":"divTableCell"}},[_vm._v(" "+_vm._s(_vm.momentTZ.tz(_vm.value, _vm.timezone).format('YYYY-MM-DD HH:mm'))+" ")])]:(
			typeof _vm.value === 'string' &&
			_vm.value.match(
				/^rawdate-(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01]).*$/
			)
		)?[_c('span',{attrs:{"id":"divTableCell"}},[_vm._v(_vm._s(_vm.value.replace('rawdate-', '')))])]:(
			typeof _vm.value === 'string' && /^#[0-9A-F]{6}$/i.test(_vm.value)
		)?[_c('span',{style:({
				['background-color']: _vm.value,
				width: '25px',
				height: '25px',
				['margin-left']: '10px'
			}),attrs:{"id":"divTableCell"}})]:(
			typeof _vm.value === 'object' && _vm.value !== null && _vm.value._isVue
		)?[_c('span',{attrs:{"id":"divTableCell"}},[_c(_vm.value.$options,{tag:"component"})],1)]:[_c('span',{attrs:{"id":"divTableCell"}},[_vm._v(_vm._s(_vm.value))])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }